import _ from "lodash";
import {
    RangeProperties,
    WeatherSearchParams
} from "../store/weatherSearchDefaults";
import { RegionBase } from "../templates/fragments/region";

let lastChangedProp: string = undefined;
let runningFetch = undefined;
let propData: WeatherSearchResultRow[] = undefined;
let lastSearchId = 0;
let regionsMap: { [id: string]: RegionBaseWithImage } = undefined;
const intervalSearchProps = [
    "tmin",
    "tmax",
    "sst",
    "wet",
    "ws",
    "rhm",
    "sunhrs",
    "dayhrs"
];
const intervalKeyTypes = [
    ["tmin", "interval"],
    ["tmax", "interval"],
    ["sst", "interval"],
    ["wet", "lessThan"],
    ["ws", "lessThan"],
    ["rhm", "lessThan"],
    ["sunhrs", "moreThan"],
    ["dayhrs", "moreThan"],
    ["rainseason", "lessThan"]
];

export interface WeatherSearchResultRowBasic {
    id: string;
    val?: number | number[];
    continent: string;
    centroid: [number, number];
    monthIndexes?: number[];
}
export interface WeatherSearchResultRowExtended
    extends WeatherSearchResultRowBasic {
    sst: number;
    tmax: number[];
    wet: number;
}

export type WeatherSearchResultApiRow =
    | WeatherSearchResultRowBasic
    | WeatherSearchResultRowExtended;
export type WeatherSearchResultRowComplete = RegionBaseWithImage &
    WeatherSearchResultRowExtended;
export type WeatherSearchResultRow =
    | WeatherSearchResultRowBasic
    | WeatherSearchResultRowComplete;
export type RegionBaseWithImage = RegionBase & { mainImgData: { url: string } };

export const search = async ({
    prop,
    searchParams,
    justStartFetch,
    extended
}: {
    prop?: string;
    searchParams: WeatherSearchParams;
    justStartFetch?: boolean;
    extended?: boolean;
}): Promise<WeatherSearchResultRow[]> => {
    const searchId = ++lastSearchId;
    const isIntervalSearch = _.includes(intervalSearchProps, prop);
    const isAllMonths = searchParams.month == "all";
    if (!prop || prop !== lastChangedProp || !isIntervalSearch) {
        fetchData({ prop, searchParams, extended });
    }
    if (justStartFetch) {
        return;
    }
    if (runningFetch) {
        await runningFetch;
    }
    // if new search request came in the meanwhile, do nothing
    if (searchId !== lastSearchId) return;

    // either return server response
    if (!prop || !isIntervalSearch) {
        return isAllMonths
            ? propData.map((r) => {
                  return { ...r, monthIndexes: r.val as number[] };
              })
            : propData;
    }
    // or do local search
    else {
        return doLocalSearch({ prop, searchParams });
    }
};

type CheckPropertiesInput = {
    min: number;
    max: number;
    val: number | number[];
    disabled?: boolean;
};
export const checkPropertyMinMax = (
    property: string,
    rangeProperties: CheckPropertiesInput
) => {
    const intervalType = intervalKeyTypes.find((t) => t[0] == property);
    if (!intervalType) return;
    let isMin,
        isMax = false;
    if (intervalType[1] == "interval") {
        let val1 = rangeProperties.val[0];
        let val2 = rangeProperties.val[1];
        if (rangeProperties.min === val1 || rangeProperties.disabled) {
            isMin = true;
            val1 = undefined;
        }
        if (rangeProperties.max === val2 || rangeProperties.disabled) {
            isMax = true;
            val2 = undefined;
        }
        return { isMin, isMax, val: [val1, val2] };
    } else {
        let val = rangeProperties.val;
        if (
            intervalType[1] == "lessThan" &&
            rangeProperties.max == rangeProperties.val
        ) {
            isMax = true;
            val = undefined;
        }
        if (
            intervalType[1] == "moreThan" &&
            rangeProperties.min == rangeProperties.val
        ) {
            isMin = true;
            val = undefined;
        }
        return { isMin, isMax, val };
    }
};

const doLocalSearch = ({
    prop,
    searchParams
}: {
    prop?: string;
    searchParams: WeatherSearchParams;
}): WeatherSearchResultRow[] => {
    const key = intervalKeyTypes.find((k) => k[0] === prop);
    const [keyName, keyType] = key;
    const isAllMonths = searchParams.month == "all";
    return propData
        .map((row) => {
            const filterValue = searchParams[keyName];
            if (_.isNil(filterValue)) {
                return isAllMonths
                    ? {
                          ...row,
                          monthIndexes: mapMonthIndexes(row.val as number[])
                      }
                    : row;
            }
            if (isAllMonths) {
                const monthIndexes = doFilterAnyMonth({
                    keyType,
                    filterValue,
                    monthValues: row.val as number[]
                });
                if (!monthIndexes.length) {
                    return undefined;
                }
                return {
                    ...row,
                    monthIndexes
                };
            } else {
                return doFilterOneMonth({
                    keyType,
                    filterValue,
                    propertyValue: row.val as number
                })
                    ? row
                    : undefined;
            }
        })
        .filter((r) => !_.isNil(r));
};

const doFilterAnyMonth = ({
    keyType,
    filterValue,
    monthValues
}: {
    keyType: string;
    filterValue: number | number[];
    monthValues: number[];
}) => {
    // intervals
    if (_.isNil(filterValue)) {
    } else if (keyType == "interval") {
        if (_.isNumber(filterValue[0])) {
            monthValues = monthValues.map((v) =>
                _.isNumber(v) ? (v < filterValue[0] ? undefined : v) : undefined
            );
        }
        if (_.isNumber(filterValue[1])) {
            monthValues = monthValues.map((v) =>
                _.isNumber(v) ? (v > filterValue[1] ? undefined : v) : undefined
            );
        }
    }
    // less than and more than
    else if (keyType == "lessThan" || keyType == "moreThan") {
        if (_.isNumber(filterValue)) {
            monthValues = monthValues.map((v) => {
                if (!_.isNumber(v)) return undefined;
                return (
                    keyType == "lessThan" ? v > filterValue : v < filterValue
                )
                    ? undefined
                    : v;
            });
        }
    }
    return mapMonthIndexes(monthValues);
};

const doFilterOneMonth = ({
    keyType,
    filterValue,
    propertyValue
}: {
    keyType: string;
    filterValue: number | number[];
    propertyValue: number;
}) => {
    // intervals
    if (keyType == "interval") {
        if (_.isNumber(filterValue[0]) && propertyValue < filterValue[0]) {
            return false;
        }
        if (_.isNumber(filterValue[1]) && propertyValue > filterValue[1]) {
            return false;
        }
    }
    // less than and more than
    if (keyType == "lessThan" || keyType == "moreThan") {
        if (
            _.isNumber(filterValue) &&
            (keyType == "lessThan"
                ? propertyValue > filterValue
                : propertyValue < filterValue)
        ) {
            return false;
        }
    }
    return true;
};

const mapMonthIndexes = (monthValues: number[]) => {
    return monthValues
        .map((v, idx) => (isNaN(v) ? undefined : idx))
        .filter((v) => !isNaN(v));
};

const fetchData = ({
    prop,
    searchParams,
    extended
}: {
    prop?: string;
    searchParams: WeatherSearchParams;
    extended?: boolean;
}) => {
    lastChangedProp = prop;
    const fetchProps = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
            ...searchParams,
            prop,
            extended: extended ? 1 : undefined
        })
    };

    //const promise: Promise<any> = fetch(`/weather-search-api/`, fetchProps)
    const promise: Promise<any> = fetch(`/weather-search-api/`, fetchProps)
        .then(async (resp) => await resp.json())
        .then((data: WeatherSearchResultApiRow[]) => {
            if (promise === runningFetch) {
                propData = extended
                    ? enhanceSearchResults(
                          data as WeatherSearchResultRowExtended[]
                      )
                    : data;
                runningFetch = undefined;
            }
        });
    runningFetch = promise;
};

const enhanceSearchResults = (
    results: WeatherSearchResultRowExtended[]
): WeatherSearchResultRowComplete[] => {
    return results.map((r) => {
        const staticRegion = regionsMap[r.id];
        return { ...r, ...staticRegion };
    });
};

export const initStaticRegions = (regions: RegionBaseWithImage[]) => {
    regionsMap = {};
    for (let region of regions) {
        regionsMap[region.id] = region;
    }
};
