import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import globalSlice from "./globalSlice";
import weatherSearchSlice from "./weatherSearchSlice";

export const store = configureStore({
    reducer: {
        weatherSearch: weatherSearchSlice,
        global: globalSlice
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>;
