import { isNil, isString } from "lodash";
import isBrowser from "./isBrowser";
enum ParamType {
    STRING = 1,
    DOUBLE,
    INT,
    BOOLEAN,
    ARR_INT,
    ARR_DOUBLE,
    ARR_STRING
}
type UrlParamKey =
    | "tmin"
    | "tmax"
    | "sst"
    | "wet"
    | "sunhrs"
    | "dayhrs"
    | "ws"
    | "rhm"
    | "month"
    | "rainseason"
    | "tag"
    | "continent"
    | "center"
    | "zoom"
    | "advancedFilter";
const allowedParams: { [key in UrlParamKey]: ParamType } = {
    tmin: ParamType.ARR_INT,
    tmax: ParamType.ARR_INT,
    sst: ParamType.ARR_INT,
    wet: ParamType.INT,
    sunhrs: ParamType.INT,
    dayhrs: ParamType.INT,
    ws: ParamType.INT,
    rhm: ParamType.INT,
    month: ParamType.STRING,
    rainseason: ParamType.STRING,
    tag: ParamType.STRING,
    continent: ParamType.STRING,
    center: ParamType.ARR_DOUBLE,
    zoom: ParamType.INT,
    advancedFilter: ParamType.BOOLEAN
};

export const writeUrlParams = (data: UrlParamsData) => {
    if (!isBrowser()) return;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    for (let key in data) {
        let val: any = data[key];
        if (isNil(val)) {
            urlParams.delete(key);
        } else {
            urlParams.set(key, val);
        }
    }
    history.replaceState(null, null, "?" + urlParams.toString());
};

export const readUrlParams = () => {
    if (!isBrowser()) return {};
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const data: UrlParamsData = {};
    urlParams.forEach((value, key) => {
        const paramType = allowedParams[key];
        if (!paramType || isNil(value)) return;

        try {
            switch (paramType) {
                case ParamType.DOUBLE:
                    data[key] = parseFloat(value);
                    break;
                case ParamType.INT:
                    data[key] = parseInt(value);
                    break;
                case ParamType.ARR_DOUBLE:
                    data[key] = value.split(",").map((v) => parseFloat(v));
                    break;
                case ParamType.ARR_INT:
                    data[key] = value.split(",").map((v) => parseInt(v));
                    break;
                default:
                    data[key] = value;
            }
            data[key] = JSON.parse(value);
        } catch (err) {}
    });
    return data;
};

export type UrlParamsData = Partial<{
    [key in UrlParamKey]: string | number | number[] | boolean;
}>;
