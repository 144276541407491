import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "@reduxjs/toolkit/node_modules/immer/dist/internal";
import _ from "lodash";
import { filterByBbox } from "../services/mapUtilsNoLeaflet";
import {
    checkPropertyMinMax,
    search,
    WeatherSearchResultRow,
    WeatherSearchResultRowComplete
} from "../services/weatherSearch";
import { AppThunk } from "./store";
import {
    createInitialState,
    RangeProperties,
    SelectProperties,
    WeatherSearchKey,
    WeatherSearchParams,
    WeatherSearchProperties,
    WeatherSearchState
} from "./weatherSearchDefaults";

const initialState = createInitialState();
export const weatherSearchSlice = createSlice({
    name: "weatherSearch",
    initialState,
    reducers: {
        valueChanged: (
            state,
            action: PayloadAction<{
                propertyName: WeatherSearchKey;
                val: string | number | number[];
            }>
        ) => {
            if (action.payload.propertyName === "tag") {
                const isBeach = action.payload.val === "beach";
                adjustValuesBeach({
                    isBeach,
                    searchProperties: state.searchProperties
                });
            }
            state.searchProperties[action.payload.propertyName].val =
                action.payload.val;
        },
        toggleExtendedSearch: (
            state,
            action: PayloadAction<{
                extendedSearch: boolean;
                delayedRenderId?: string;
            }>
        ) => {
            state.extendedSearch = action.payload.extendedSearch;
            state.delayedRenderId = action.payload.delayedRenderId;
            if (!state.extendedSearch) {
                state.zoom = undefined;
                state.bounds = undefined;
            }
            state.searchResultsExtended = undefined;
            if (state.monthToBeSet) {
                state.searchProperties.month.val = state.monthToBeSet;
                state.monthToBeSet = undefined;
            }
            resetAdvancedFilterProperties(state);
            state.advancedFilter = false;
            state.searchResults = action.payload
                ? undefined
                : state.searchResultsExtended;
        },
        toggleShowMapResultsMobile: (state, action: PayloadAction<boolean>) => {
            state.showMapResultsMobile = action.payload;
        },
        toggleResponsiveView: (state, action: PayloadAction<boolean>) => {
            state.isResponsiveView = action.payload;
        },

        resultsReceived: (
            state,
            action: PayloadAction<WeatherSearchResultRow[]>
        ) => {
            state.searchResults = state.extendedSearch
                ? undefined
                : action.payload;
            state.searchResultsExtended = state.extendedSearch
                ? (action.payload as WeatherSearchResultRowComplete[])
                : undefined;
            if (
                state.extendedSearch &&
                state.bounds &&
                state.searchResultsExtended
            ) {
                state.searchResultsForCards = filterByBbox({
                    results: state.searchResultsExtended,
                    bounds: state.bounds
                });
            }
        },
        setLocalization: (
            state,
            action: PayloadAction<{ [key: string]: string }>
        ) => {
            state.loc = action.payload;
            for (let prop in state.searchProperties) {
                const options =
                    (
                        state.searchProperties[prop] as SelectProperties<
                            number | string
                        >
                    ).options || [];
                for (let option of options) {
                    option.label = state.loc[option.labelIdx];
                }
            }
        },
        setHoveredRegion: (state, action: PayloadAction<string>) => {
            state.hoveredRegion = action.payload;
        },
        setMonth: (state, action: PayloadAction<string>) => {
            state.monthToBeSet = action.payload;
            state.monthToBeSet =
                state.monthToBeSet === "climate" ? "all" : state.monthToBeSet;
        },
        setValuesBeforeInit: (
            state,
            action: PayloadAction<{ month?: string; continent?: string }>
        ) => {
            if (action.payload.month) {
                state.searchProperties.month.val = action.payload.month;
            }
            if (action.payload.continent) {
                state.searchProperties.continent.val = action.payload.continent;
            }
        },
        setBoundsAndZoom: (
            state,
            action: PayloadAction<{ zoom: number; bounds: string }>
        ) => {
            state.zoom = action.payload.zoom;
            state.bounds = action.payload.bounds;
            if (
                !state.extendedSearch ||
                !state.bounds ||
                !state.searchResultsExtended
            ) {
                return;
            }
            state.searchResultsForCards = filterByBbox({
                results: state.searchResultsExtended,
                bounds: state.bounds
            });
        },
        setInInteraction: (state, action: PayloadAction<boolean>) => {
            state.inInteraction = action.payload;
            if (
                !action.payload &&
                state.extendedSearch &&
                state.searchResultsExtended &&
                state.bounds
            ) {
                state.searchResultsForCards = filterByBbox({
                    results: state.searchResultsExtended,
                    bounds: state.bounds
                });
            }
        },
        setContinentRefresher: (state, action: PayloadAction<string>) => {
            state.continentRefresher = action.payload;
        },
        toggleAdvancedFilters: (state, action: PayloadAction<boolean>) => {
            state.advancedFilter = action.payload;
        }
    }
});

// THUNKS
export const valueChanged =
    ({
        prop,
        val
    }: {
        prop: WeatherSearchKey;
        val: string | number | number[];
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        if (state.weatherSearch.searchProperties[prop].val === val) {
            return state;
        }
        dispatch(
            weatherSearchSlice.actions.valueChanged({ propertyName: prop, val })
        );
        const excludeAdvanced =
            !state.weatherSearch.advancedFilter ||
            !state.weatherSearch.extendedSearch;
        const searchParams = stateToSearchParams(
            state.weatherSearch.searchProperties,
            excludeAdvanced
        );
        const result = await search({
            prop,
            searchParams,
            extended: state.weatherSearch.extendedSearch
        });
        dispatch(weatherSearchSlice.actions.resultsReceived(result));
    };

export const valueInteractionStarted =
    (prop: WeatherSearchKey): AppThunk =>
    async (dispatch, getState) => {
        dispatch(weatherSearchSlice.actions.setInInteraction(true));
        const state = getState();
        const excludeAdvanced =
            !state.weatherSearch.advancedFilter ||
            !state.weatherSearch.extendedSearch;
        const searchParams = stateToSearchParams(
            state.weatherSearch.searchProperties,
            excludeAdvanced
        );
        await search({
            prop,
            searchParams,
            justStartFetch: true,
            extended: state.weatherSearch.extendedSearch
        });
    };

export const weatherSearchFormInit =
    (extendedSearch: boolean, delayedRenderId?: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(
            weatherSearchSlice.actions.toggleExtendedSearch({
                extendedSearch,
                delayedRenderId
            })
        );
        const state = getState();
        if (!state.weatherSearch.searchResults || extendedSearch) {
            const searchParams = stateToSearchParams(
                state.weatherSearch.searchProperties,
                true
            );
            const result = await search({
                searchParams,
                extended: state.weatherSearch.extendedSearch
            });
            dispatch(weatherSearchSlice.actions.resultsReceived(result));
        }
    };

export const toggleAdvancedFilters =
    (advancedFilters: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(
            weatherSearchSlice.actions.toggleAdvancedFilters(advancedFilters)
        );
        const state = getState();
        const searchParams = stateToSearchParams(
            state.weatherSearch.searchProperties,
            !state.weatherSearch.advancedFilter
        );
        const result = await search({
            searchParams,
            extended: state.weatherSearch.extendedSearch
        });
        dispatch(weatherSearchSlice.actions.resultsReceived(result));
    };

// END OF THUNKS

const checkRange = (props: RangeProperties) => {
    const val = props.val as number[];
    if (val[0] < props.min) {
        return [props.min, val[1]];
    }
    return val;
};

const resetAdvancedFilterProperties = (
    state: WritableDraft<WeatherSearchState>
) => {
    state.searchProperties.rhm.val = (
        state.searchProperties.rhm as RangeProperties
    ).max;
    state.searchProperties.ws.val = (
        state.searchProperties.ws as RangeProperties
    ).max;
    state.searchProperties.sunhrs.val = (
        state.searchProperties.sunhrs as RangeProperties
    ).min;
    state.searchProperties.dayhrs.val = (
        state.searchProperties.dayhrs as RangeProperties
    ).min;
};

const stateToSearchParams = (
    searchProperties: WeatherSearchProperties,
    excludeAdvancedFilterProperties: boolean
): WeatherSearchParams => {
    return _.mapValues(searchProperties, (v, key) => {
        if ((v as RangeProperties).disabled) return undefined;
        if (key == "sst" && searchProperties.tag.val != "beach")
            return undefined;
        if (
            excludeAdvancedFilterProperties &&
            ["rhm", "ws", "sunhrs", "dayhrs"].includes(key)
        )
            return undefined;
        const checkedMinMax = checkPropertyMinMax(key, v as RangeProperties);
        if (!checkedMinMax) {
            return v.val;
        }
        return checkedMinMax.val;
    });
};

const adjustValuesBeach = ({
    isBeach,
    searchProperties
}: {
    isBeach: boolean;
    searchProperties: WritableDraft<WeatherSearchProperties>;
}) => {
    const tmin = searchProperties.tmin as RangeProperties;
    tmin.min = isBeach
        ? 6
        : (initialState.searchProperties.tmin as RangeProperties).min;

    const tmax = searchProperties.tmax as RangeProperties;
    tmax.min = isBeach
        ? 16
        : (initialState.searchProperties.tmax as RangeProperties).min;
    // extending search for beach->non-beach change
    if (!isBeach && searchProperties.tag.val == "beach") {
        tmin.val[0] = Math.min(0, tmin.val[0]);
        tmax.val[0] = Math.min(6, tmax.val[0]);
    }

    tmin.val = checkRange(tmin);
    tmax.val = checkRange(tmax);
    const sst = searchProperties.sst as RangeProperties;
    sst.min = isBeach
        ? 16
        : (initialState.searchProperties.sst as RangeProperties).min;
    sst.val = checkRange(sst);
    sst.disabled = !isBeach;
};

export default weatherSearchSlice.reducer;
export let setLocalization = weatherSearchSlice.actions.setLocalization;
export let setMonth = weatherSearchSlice.actions.setMonth;
export let setBoundsAndZoom = weatherSearchSlice.actions.setBoundsAndZoom;
export let setInInteraction = weatherSearchSlice.actions.setInInteraction;
export let setContinentRefresher =
    weatherSearchSlice.actions.setContinentRefresher;
export let toggleResponsiveView =
    weatherSearchSlice.actions.toggleResponsiveView;
export let setHoveredRegion = weatherSearchSlice.actions.setHoveredRegion;
export let setValuesBeforeInit = weatherSearchSlice.actions.setValuesBeforeInit;
