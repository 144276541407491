import { WeatherSearchResultRowComplete } from "./weatherSearch";

export const filterByBbox = ({
    bounds,
    results
}: {
    bounds: string;
    results: WeatherSearchResultRowComplete[];
}) => {
    const boundsSplitted = bounds
        ? bounds.split(",").map((v) => parseFloat(v))
        : [];
    if (!boundsSplitted.length) {
        return results;
    }
    const ratio = 0.03;
    const lngMin = boundsSplitted[0] - (boundsSplitted[2] - boundsSplitted[0]) * ratio;
    const lngMax = boundsSplitted[2] + (boundsSplitted[2] - boundsSplitted[0]) * ratio;
    const latMin = boundsSplitted[1] - (boundsSplitted[3] - boundsSplitted[1]) * ratio;
    const latMax = boundsSplitted[3] + (boundsSplitted[3] - boundsSplitted[1]) * ratio;
    
    return results.filter((r) => {
        const centroid = [r.centroid[1], r.centroid[0]];
        return centroid[0]<latMax && centroid[0]>latMin && centroid[1]<lngMax && centroid[1]>lngMin;
    });
};