import { activities, continents, monthSlugs } from "../services/centralStore";
import getDefaultMonthIndex from "../services/getDefaultMonthIndex";
import { readUrlParams } from "../services/urlParams";
import {
    WeatherSearchResultRowBasic,
    WeatherSearchResultRowComplete
} from "../services/weatherSearch";

export interface RangeProperties {
    min: number;
    max: number;
    units: string;
    disabled?: boolean;
    val: number | number[];
}

export interface SelectProperties<T> {
    val: T;
    options: { label: string; labelIdx: string; value: T }[];
    placeholder?: string;
}
export type WeatherSearchKey =
    | "tmin"
    | "tmax"
    | "sst"
    | "wet"
    | "tag"
    | "month"
    | "continent"
    | "rainseason"
    | "ws"
    | "rhm"
    | "sunhrs"
    | "dayhrs";
export type WeatherSearchProperties = {
    [key in WeatherSearchKey]:
        | SelectProperties<number | string>
        | RangeProperties;
};
export type WeatherSearchState = {
    loc: { [key: string]: string };
    delayedRenderId: string;
    units: string;
    queryProperty: WeatherSearchKey;
    loading: boolean;
    hoveredRegion: string;
    inInteraction: boolean;
    extendedSearch: boolean;
    showMapResultsMobile: boolean;
    isResponsiveView: boolean;
    searchProperties: WeatherSearchProperties;
    searchResults: WeatherSearchResultRowBasic[];
    searchResultsExtended: WeatherSearchResultRowComplete[];
    searchResultsForCards: WeatherSearchResultRowComplete[];
    searchResultsInBounds: WeatherSearchResultRowComplete[];
    continentRefresher: string;
    bounds: string;
    zoom: number;
    monthToBeSet: string;
    advancedFilter: boolean;
    excludedProperties: WeatherSearchKey[];
};

export type WeatherSearchParams = {
    [key in WeatherSearchKey]: string | number | number[];
};

export const createInitialState: () => WeatherSearchState = () => {
    const fromUrl = readUrlParams() || {};
    return {
        loc: undefined,
        units: "C",
        delayedRenderId: undefined,
        inInteraction: false,
        queryProperty: undefined,
        loading: false,
        hoveredRegion: undefined,
        searchResults: undefined,
        searchResultsExtended: undefined,
        searchResultsForCards: undefined,
        searchResultsInBounds: undefined,
        continentRefresher: undefined,
        extendedSearch: false,
        showMapResultsMobile: true,
        isResponsiveView: false,
        bounds: undefined,
        zoom: undefined,
        monthToBeSet: undefined,
        advancedFilter: (fromUrl.advancedFilter as boolean) || false,
        excludedProperties: [],
        searchProperties: {
            rhm: {
                min: 60,
                max: 90,
                units: "%",
                val: (fromUrl.rhm as number) || 90
            },
            ws: {
                min: 1,
                max: 5,
                val: (fromUrl.ws as number) || 5,
                units: ""
            },
            sunhrs: {
                min: 4,
                max: 12,
                val: (fromUrl.sunhrs as number) || 4,
                units: ""
            },
            dayhrs: {
                min: 6,
                max: 14,
                val: (fromUrl.dayhrs as number) || 6,
                units: ""
            },
            tmin: {
                min: -6,
                max: 30,
                val: (fromUrl.tmin as number[]) || [6, 30],
                units: "deg"
            },
            tmax: {
                min: 0,
                max: 36,
                val: (fromUrl.tmax as number[]) || [16, 36],
                units: "deg"
            },
            sst: {
                min: 16,
                max: 32,
                val: (fromUrl.sst as number[]) || [18, 32],
                units: "deg"
            },
            wet: {
                min: 2,
                max: 20,
                val: (fromUrl.wet as number) || 15,
                units: ""
            },
            tag: {
                val: (fromUrl.tag as string) || "beach",
                options: activities.map((a) => {
                    return {
                        value: a,
                        label: "",
                        labelIdx: `common.enjoyActivities.${a}`
                    };
                })
            },
            month: {
                val:
                    (fromUrl.month as string) ||
                    monthSlugs[getDefaultMonthIndex()],
                options: ["all", ...monthSlugs].map((m) => {
                    return {
                        value: m,
                        label: "",
                        labelIdx: `common.months.${m}`
                    };
                })
            },
            continent: {
                val: (fromUrl.continent as string) || "all",
                options: ["all", ...continents].map((c) => {
                    return {
                        value: c,
                        label: "",
                        labelIdx: `common.continents.${
                            c == "all" ? "anywhere" : c
                        }`
                    };
                })
            },
            rainseason: {
                val: (fromUrl.rainseason as number) || 1,
                options: [
                    {
                        value: 0,
                        label: "",
                        labelIdx: "common.preferredSeason.dry"
                    },
                    {
                        value: 1,
                        label: "",
                        labelIdx: "common.preferredSeason.outOfWet"
                    },
                    {
                        value: 2,
                        label: "",
                        labelIdx: "common.doesNotMatter"
                    }
                ]
            }
        }
    };
};
