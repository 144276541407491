import "leaflet/dist/leaflet.css";
import * as React from "react";
import { AppContext } from "./src/context/app-context";
import { PageContext } from "./src/context/page-context";
import "./src/declaration.d.ts";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
    return <AppContext>{element}</AppContext>;
};

export const wrapPageElement = ({ element, props }) => {
    return (
        <PageContext month={props?.pageContext?.month}>{element}</PageContext>
    );
};
