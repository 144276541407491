import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export const globalSlice = createSlice({
    name: "Global",
    initialState: {
        isLoading: false
    },
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});
export default globalSlice.reducer;
export let setLoading = globalSlice.actions.setLoading;
